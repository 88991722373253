<template>
  <loading
    :active="globalLoading"
  />
  <router-view />
</template>
<script>

import Loading from "vue-loading-overlay";
import { mapActions, mapMutations, mapState } from 'vuex';
import { statService, eventIds } from '@/utils/statService';
import urlHelper from '@/utils/url.helper';
import api from '@/services/api';

const GET = urlHelper.parseQueryString(location.href).GET;
if (GET.userId && GET.rand && GET.session) {
  const params = {response: GET};
  api.updateRequestParams(params, false);
}
if (GET.channel) {
  statService.init({
    channel: GET.channel
  });
}
function uuidv4 () {
  let d = new Date().getTime()
  let d2 = (performance && performance.now && performance.now() * 1000) || 0
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16
    if (d > 0) {
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export default {
  name: 'App',
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      globalLoading: (state) => state.globalLoading,
    }),
  },
  async mounted() {
    this.loaded();
    statService.setPostHog(this.$posthog);
    statService.logEvent(eventIds.appLoaded, 'webview_exp');
    this.setGlobalLoading(false);
    this.$store.commit('setExperimentCode', GET.experimentCode);
    this.$store.commit('setChannel', GET.channel);

    const url = new URL(location.href)

    // Фиксируем UTM-метки, если они есть в URL
    if (url.search.includes('utm')) {
      await api.post('/105/api/log/web/loadedScreen', {
        trackingId: uuidv4(),
        userId: GET.userId || null,
        screenName: 'qh_utm_checker',
        referer: document.referrer,
        screenData: {
          url: url.toString(),
          params: GET
        },
      });
    }
  },
  methods: {
    ...mapActions({
      loaded: 'communications/sendLoadedEvent',
    }),
    ...mapMutations({
      setGlobalLoading: 'setGlobalLoading',
    })
  },
}
</script>
