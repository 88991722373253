(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
m[i].l=1*new Date();
for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

function getFilteredParams() {
    const url = new URL(window.location.href);
    const params = {};

    url.searchParams.forEach((value, key) => {
        // Исключаем параметры "rand" и "session"
        if (key !== 'rand' && key !== 'session') {
            params[key] = value;
        }
    });

    return params;
}

ym(98718633, "init", {
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true,
    userParams: getFilteredParams()
});
